import { css } from '@emotion/react'
import { Divider, Paper } from '@mui/material'
import { memo } from 'react'
import useMedia from '~/hooks/useMedia'
import { globalGrey } from '~/modules/AppLayout/Colors'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch'
import { CollapseContent } from '~/pages/futuresai/_private/components/CollapseContent'
import { huanan_store } from '~/pages/david0705/trading_analyzer/huanan_store'
import { StocksList } from '~/pages/david0705/trading_analyzer/StocksList'

export const T3j_SidePane1 = memo<ReactProps>(function T3j_SidePane1(props) {
  const { isPc } = useMedia()

  return (
    <Paper
      className={props.className}
      css={css`
        border-right: 1px solid ${globalGrey.g400};
        padding: 1px;
        overflow-y: scroll;
        ${isPc && 'overflow-x: hidden;'}
        ${isPc && 'height: 100vh;'}
      `}
    >
      <ChartingServerSwitch charting={huanan_store.charting}></ChartingServerSwitch>

      <Divider />

      <CollapseContent
        localStorageKey='drawers.futures-list'
        displayTitle='熱門幣報價'
      >
        <StocksList
          charting={huanan_store.charting}
          symbolStrings={[
            'BTCUSDT',
            'ETHUSDT',
            'BNBUSDT',
            'XRPUSDT',
            'SOLUSDT',
            'ADAUSDT',
            'DOGEUSDT',
            'AVAXUSDT',
            'DOTUSDT',
          ]}
        />
      </CollapseContent>
    </Paper>
  )
})
