import { memo } from 'react'
import { ChartingModule } from '~/modules/SDK/chart4/ChartingModule'
import { ChartingSymbolQuoting } from '~/modules/SDK/chart4/components/ChartingSymbolQuoting'

export const StocksList = memo<
  ReactProps<{
    charting: ChartingModule
    symbolStrings: string[]
  }>
>(function StocksList(props) {
  return (
    <div>
      {props.symbolStrings.map(symbolString => {
        return (
          <ChartingSymbolQuoting
            key={symbolString}
            symbol={symbolString}
            charting={props.charting}
          />
        )
      })}
    </div>
  )
})
