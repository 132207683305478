import { memo } from 'react'
import { useSymbolDictionaryStore } from './useSymbolDictionaryStore'
import { Autocomplete } from '@mantine/core'
import { Autocomplete as MuiAutocomplete, TextField } from '@mui/material'
import { map } from 'lodash'
import { fr_instrument } from '~/pages/heineken_template/_fr/fr_instrument'

type SymbolKv = {
  symbol: string
  name: string
}

export const SymbolSearch = memo<
  ReactProps<{
    onChangeCb: (item: SymbolKv) => void
    size?: 'small' | 'medium'
    defaultValue?: SymbolKv
    variant?: 'outlined'
  }>
>(function SymbolSearch({ onChangeCb, size, defaultValue, variant }) {
  const dictionary = useSymbolDictionaryStore(s => s.dictionary)

  const stockSymbols: SymbolKv[] = Object.entries(dictionary).map(s => ({
    symbol: s[0],
    name: s[1],
  }))

  return (
    <Autocomplete
      size='md'
      filter={(value, item) => {
        return (
          item.value.toLowerCase().includes(value.toLowerCase()) ||
          item.label.replace(/-/gi, '').toLowerCase().includes(value.toLowerCase())
        )
      }}
      data={map(stockSymbols, item => ({
        label: `${item.name} - ${item.symbol}`,
        value: item.symbol,
      }))}
      onItemSubmit={item => {
        onChangeCb({
          symbol: item.value,
          name: fr_instrument.getName(item.value),
        })
      }}
      placeholder='新增更多自選股...'
      limit={50}
      maxDropdownHeight={400}
      dropdownPosition='bottom'
    />
  )
})

export const MultipleSymbolSearch = memo<
  ReactProps<{
    onChangeCb: (item: SymbolKv[]) => void
    selectedItems?: SymbolKv[]
  }>
>(function MultipleSymbolSearch({ onChangeCb, selectedItems }) {
  const dictionary = useSymbolDictionaryStore(s => s.dictionary)

  const stockSymbols: SymbolKv[] = Object.entries(dictionary).map(s => ({
    symbol: s[0],
    name: s[1],
  }))

  return (
    <MuiAutocomplete
      fullWidth
      multiple
      value={selectedItems}
      options={stockSymbols}
      getOptionLabel={option => `${option.symbol} ${option.name}`}
      onChange={(_, value) => {
        onChangeCb(value)
      }}
      renderInput={params => (
        <TextField
          {...params}
          size='small'
          label='自選個股'
          variant='standard'
        />
      )}
    />
  )
})
