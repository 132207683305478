import { css } from '@emotion/react'
import React, { memo, SyntheticEvent, useState } from 'react'
import { BsTrash } from 'react-icons/bs'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { Socket } from '~/modules/SDK/socket2/Socket'
import { SignalrQuote } from '~/modules/SDK/Signalr/SignalrQuote'
import { useInterval } from 'react-use'
import { globalRed } from '~/modules/AppLayout/Colors'
import { SymbolSearch } from '~/modules/SDK/Symbol/SymbolSearch'
import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { SnackbarMessage } from '~/components/SnackbarWarning'
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList'

/** Futuresai主站版型 */
const SelfSelectSymbols = memo<
  ReactProps<{
    selfSelectSymbolGroupName: string
    activateTool: (
      props: ReactProps<{
        symbol: Socket.SymbolString
        indicators: ChartTypes.Indicator[]
        render: (symbol: any) => JSX.Element
      }>,
    ) => JSX.Element
  }>
>(function SelfSelectSymbols(props) {
  const [errorOpen, setErrorOpen] = useState(false)
  const handleClose = (_: Event | SyntheticEvent<Element, Event>, reason?: string) => {
    if (reason === 'clickaway') return
    setErrorOpen(false)
  }
  const { state: userStockListState, acts: userStockListActs } = useSymbolWatchList(
    props.selfSelectSymbolGroupName,
  )

  const symbolAdd = (newValue: { symbol: string; name: string }) => {
    /** 沒選擇任何標的時 */
    if (userStockListState.symbolListArray?.find(s => s === newValue.symbol)) {
      setErrorOpen(true)
      return
    }
    /** 丟入表格的格式 */
    userStockListActs.addSymbol(newValue.symbol, 100)
  }

  const Child = (
    p: ReactProps<{
      _symbol: Socket.SymbolString
      _indicators: ChartTypes.Indicator[]
    }>,
  ) => {
    return (
      <props.activateTool
        symbol={p._symbol}
        indicators={p._indicators}
        render={symbol => <SignalrQuote symbol={p._symbol}></SignalrQuote>}
      />
    )
  }

  useInterval(() => {
    if (!userStockListState.symbolListArray) {
      userStockListActs.getSymbolList()
    }
  }, 5000)

  return (
    <div>
      <div
        css={css`
          ${flex.h.allCenter};
          padding: 8px 16px;
        `}
      >
        <SymbolSearch onChangeCb={symbolAdd} />
      </div>
      <div>
        {userStockListState.symbolListArray?.map((a, i) => (
          <div
            key={i}
            css={css`
              position: relative;
              margin: 8px;
              ${flex.h.crossCenter};
            `}
          >
            <Child
              key={i}
              _symbol={a as Socket.SymbolString}
              _indicators={[]}
            />
            <BsTrash
              css={trashIconCss}
              onClick={() => userStockListActs.removeSymbol(a)}
            />
          </div>
        ))}
        <SnackbarMessage
          open={errorOpen}
          closeDelegate={handleClose}
          message='重複輸入'
        />
      </div>
    </div>
  )
})

const trashIconCss = css`
  position: absolute;
  right: 8px;
  font-size: 1rem;
  cursor: pointer;
  :hover {
    color: ${globalRed.r700};
  }
`

export default SelfSelectSymbols
