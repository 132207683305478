import { memo } from 'react'
import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { ChartingModule } from '~/modules/SDK/chart4/ChartingModule'
import { SignalrQuote } from '~/modules/SDK/Signalr/SignalrQuote'

/**
 * - 此組件同時展示當前 chart 的 symbol 的 signalr 報價
 * - 也同時能夠按下切換 chart 的 symbol 和 indicators
 * - 它就類似於以前的 `<chart.SymbolIndicatorsSwitch />`
 *
 * @example
 *   ;<ChartingSymbolQuoting
 *     symbol={state.symbol}
 *     onClickLoadIndicators={chartingStoreOfStrategy.state.configs[0].indicators}
 *     charting={chartingStore}
 *     css={css`
 *       background-color: white;
 *     `}
 *   />
 */
export const ChartingSymbolQuoting = memo<
  ReactProps<{
    symbol: string
    onClickLoadIndicators?: ChartTypes.Indicator[]
    charting: ChartingModule
  }>
>(function ChartingSymbolQuoting(props) {
  return (
    <SignalrQuote
      className={props.className}
      symbol={props.symbol}
      onClick={event => {
        props.charting.changeSymbol(props.symbol)
        props.charting.replaceIndicators(props.onClickLoadIndicators || [])
      }}
    ></SignalrQuote>
  )
})
