import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { david_store } from '~/pages/david0705/david_store'


import dayAPI from '~/utils/dayAPI'

//用於:RangeZone Component
//目前支援 08:45與15:00 開盤時段重計高低
//TX-1 TXAM-1 TWN-1
//預計未來支援 NQ-1 YM-1 ES-1 CL-1 GC-1
export const huanan_DailyRange = createIndicator({
  id: 'huanan-DailyRange',
  displayName: 'huanan-DailyRange',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const itime = this.PineJS.Std.time(this._context)
      const hour = dayAPI(itime).hour()
      const minute = dayAPI(itime).minute()

      const high = this.ohlc.high
      const low = this.ohlc.low
      const close = this.ohlc.close

      const rangeHigh = this._context.new_var()
      const rangeLow = this._context.new_var()

      rangeHigh.get(1)
      rangeLow.get(1)

      /** 國內日盤交易時段 */
      const indexTradeTime = hour >= 8 && hour < 15

      //日盤
      if (hour === 8 && minute === 45) {
        rangeHigh.set(high)
        rangeLow.set(low)

        //開盤時 將夜盤的高低傳出去
        david_store.rangeHigh = rangeHigh.get(1)
        david_store.rangeLow = rangeLow.get(1)
      }

      //夜盤
      if (hour === 15 && minute === 0) {
        rangeHigh.set(high)
        rangeLow.set(low)

        //開盤時 將日盤的高低傳出去
        david_store.rangeHigh = rangeHigh.get(1)
        david_store.rangeLow = rangeLow.get(1)
      }

      //日盤
      if (!indexTradeTime) {
        if (high > rangeHigh.get(0)) {
          rangeHigh.set(high)
        }
        if (low < rangeLow.get(0)) {
          rangeLow.set(low)
        }
      }

      //夜盤
      if (indexTradeTime) {
        if (high > rangeHigh.get(0)) {
          rangeHigh.set(high)
        }
        if (low < rangeLow.get(0)) {
          rangeLow.set(low)
        }
      }

      david_store.close = close

      return [null]
    },
  },

  metainfo: {
    is_price_study: true,
    _metainfoVersion: 42,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,
    plots: [{ id: 'plot0', type: 'line' }],
    defaults: {
      styles: {
        plot0: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#EA7500',
        },
      },
      precision: 2,
      inputs: {},
    },
    styles: {
      plot0: {
        title: 'SMA',
        histogramBase: 0,
        joinPoints: false,
      },
    },
    inputs: [],
  },
})
