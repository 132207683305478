import { css } from '@emotion/react'
import { Collapse, ListItem, ListItemText } from '@mui/material'
import { memo } from 'react'
import { MdExpandLess, MdExpandMore } from 'react-icons/md'
import { useAppLocalStorage } from '~/hooks/useAppLocalStorage'

export const CollapseContent = memo<
  ReactProps<{
    displayTitle: string

    /**
      將 state 記錄到 localStorage 嗎？

      ## 若 `localStorageKey={'drawers.gauge'}`

      會記錄 state 到瀏覽器的 localStorage.setItem(`${agentName}.drawers.gauge`)
    */
    localStorageKey: string
  }>
>(function CollapseContent(props) {
  const [openState, setOpenState, removeOpenState] = useAppLocalStorage(props.localStorageKey, true)

  return (
    <div className={props.className}>
      <ListItem
        css={css`
          user-select: none;
          &:hover {
            cursor: pointer;
          }
        `}
        onClick={() => {
          setOpenState(!openState)
        }}
      >
        <ListItemText primary={props.displayTitle} />
        {openState ? <MdExpandLess /> : <MdExpandMore />}
      </ListItem>

      <Collapse
        in={openState ?? true}
        unmountOnExit
      >
        {props.children}
      </Collapse>
    </div>
  )
})
