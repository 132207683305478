import dayjs from 'dayjs'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

/** 成交量 */
export const extremeVolumeHuanan = createIndicator({
  displayName: '成交量',
  id: 'bolevolume',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const close = this.PineJS.Std.close(this._context)
      const close_array = this._context.new_var(close)
      const volume = this.PineJS.Std.volume(this._context)
      const symbol = this.PineJS.Std.ticker(this._context)

      const o = this.PineJS.Std.open(this._context)
      const h = this.PineJS.Std.high(this._context)
      const l = this.PineJS.Std.low(this._context)
      const c = this.PineJS.Std.close(this._context)
      const v = this.PineJS.Std.volume(this._context)
      const volume_array = this._context.new_var(volume)

      const datetime = dayjs(this.PineJS.Std.time(this._context))
      const isAmSession = datetime.hour() >= 8 && datetime.hour() <= 13

      const period = this.PineJS.Std.period(this._context)

      /*
        跳爆量警示：
        主圖K時間頻率開：
        1（default)；3；5
        跳爆量警示：
        一分K：
        日盤：1500&2000
        夜盤：550&800（下調）
        三分K：
        日盤：3750&5000（下調）
        夜盤：1150&1800（下調）
        五分K：
        日盤：5250&7500（下調）
        夜盤：1600&2300（下調）
      */

      const isUpBar = c >= o
      let threshold_1 = 999999999
      let threshold_2 = 999999999
      if (symbol.includes('TX-')) {
        if (period === '1') {
          threshold_1 = isAmSession ? 1500 : 550
          threshold_2 = isAmSession ? 2000 : 800
        } else if (period === '3') {
          threshold_1 = isAmSession ? 3750 : 1150
          threshold_2 = isAmSession ? 5000 : 1800
        } else if (period === '5') {
          threshold_1 = isAmSession ? 5250 : 1600
          threshold_2 = isAmSession ? 7500 : 2300
        }
      }
      let volIndicator_1 = NaN
      let volIndicator_2 = NaN
      let verticalLine = NaN
      if (v > threshold_2) {
        if (isUpBar) {
          volIndicator_2 = v * 1.5
          verticalLine = v * 100
        } else {
          volIndicator_2 = v * 1.5
          verticalLine = v * 100
        }
      } else if (v > threshold_1) {
        if (isUpBar) {
          volIndicator_1 = v * 1.5
          verticalLine = v * 100
        } else {
          volIndicator_1 = v * 1.5
          verticalLine = v * 100
        }
      }

      const volma1 = this.PineJS.Std.sma(volume_array, 5, this._context)
      const volma2 = this.PineJS.Std.sma(volume_array, 10, this._context)

      const colorIndex = close_array.get(0) >= close_array.get(1) ? 0 : 1

      return [v, colorIndex, volIndicator_1, volIndicator_2, verticalLine]
    },
  },
  metainfo: {
    precision: 0,
    //宣告
    plots: [
      {
        id: 'vol',
        type: 'line',
      },
      {
        id: 'volumePalette',
        type: 'colorer',
        target: 'vol',
        palette: 'volumePalette',
      },

      {
        id: 'plot_2',
        type: 'chars',
      },
      { id: 'plot_3', type: 'chars' },
      {
        id: 'plot_vline',
        type: 'histogram',
      },
    ],

    palettes: {
      volumePalette: {
        colors: {
          0: {
            name: '量',
          },
          1: {
            name: '量',
          },
        },
      },
    },

    defaults: {
      //樣式調整
      styles: {
        //--
        plot_0: {
          transparency: 0,
          visible: !0,
        },
        plot_2: {
          color: '#9933ff',
          textColor: '#9933ff',
          transparency: 20,
          visible: true,
        },
        plot_3: {
          color: '#ff0066',
          textColor: '#ff0066',
          transparency: 20,
          visible: true,
        },
        plot_vline: {
          linestyle: 1,
          visible: true,
          linewidth: 1,
          plottype: 1,
          trackPrice: false,
          transparency: 40,
          color: '#cccc00',
        },
        //--
        vol: {
          linestyle: 0,
          linewidth: 5,
          plottype: 5,
          trackPrice: !1,
          transparency: 10,
          visible: !0,
          color: '#02aa22',
        },
      },
      //填充區域
      palettes: {
        volumePalette: {
          colors: {
            0: {
              color: '#ff0000',
              width: 0,
              style: 0,
            },
            1: {
              color: '#00ff00',
              width: 0,
              style: 0,
            },
          },
        },
      },
    },
    styles: {
      vol: {
        title: '成交量',
        histogramBase: 0,
        joinPoints: !1,
      },
      plot_2: {
        isHidden: false,
        location: 'Absolute',
        char: '*',
        size: 'tiny',
        text: '',
      },
      plot_3: {
        isHidden: false,
        location: 'Absolute',
        char: '*',
        size: 'small',
        text: '',
      },
      plot_vline: {
        title: '垂直線',
        histogramBase: 0,
      },
    },
    is_price_study: !1,
    inputs: [],
    scriptIdPart: '',
  },
})
